<template>
	<div id="body">
		<!-- 近期消费折线图开始 -->
		<div class="topBox">
			<div class="chartTitle">
				<p class="facility">近期消费</p>
				<ul>
					<li v-for="(item, index) in dateList" :key="index" @click="Atpitch(index)"
						:class="currentIndex == index ? 'active' : ''">
						{{ item }}
					</li>
				</ul>
			</div>
			<div v-loading="dcLoading">
				<div id="echar" style="height: 300px;"></div>
			</div>
		</div>
		<!-- 近期消费折线图结束 -->
		<!-- 账户记录开始 -->
		<div class="bottomBox">
			<!-- 账户记录头 -->
			<div class="headerBox">
				<p>账户记录</p>
			</div>
			<!-- 账户记录列表 -->
			<div style="display:flex;justify-content: start;padding: 20px;align-items: center;">

				<div style="display:flex;align-items: center;margin-right:20px;">交易类型：
					<el-select v-model="tradeType" placeholder="请选择" @change="onSearch" clearable>
						<el-option v-for="item in tradeTypeOptions" :key="item.id" :label="item.label"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
				<div style="display:flex;align-items: center;margin-right:20px;">交易时间：
					<el-date-picker v-model="tradeDateRange" type="daterange" range-separator="至" value-format="yyyyMMdd"
						start-placeholder="开始日期" end-placeholder="结束日期" @change="onSearch">
					</el-date-picker>
				</div>
				<div>
					<el-link style="margin-right:20px;" :style="{color:searchKey==7?'#1890FF':''}" @click="onSearch(7)">近一周</el-link>
					<el-link style="margin-right:20px;" :style="{color:searchKey==30?'#1890FF':''}" @click="onSearch(30)">近一月</el-link>
					<el-link style="margin-right:20px;" :style="{color:searchKey==90?'#1890FF':''}" @click="onSearch(90)">近三月</el-link>
					<el-link style="margin-right:20px;" :style="{color:searchKey==180?'#1890FF':''}" @click="onSearch(180)">近半年</el-link>
					<el-link style="margin-right:20px;" :style="{color:searchKey==365?'#1890FF':''}" @click="onSearch(365)">近一年</el-link>
				</div>

			</div>
			<el-table :data="adverData" v-loading="loading">
				<el-table-column prop="use_date" label="交易时间" align="center"></el-table-column>
				<el-table-column prop="use_type" label="交易类型" align="center"></el-table-column>
				<el-table-column prop="use_num" label="交易金额" align="center"></el-table-column>
				<el-table-column prop="balance" label="账户余额" align="center"></el-table-column>
				<el-table-column prop="remark" label="备注说明" align="center">
					
					<template slot-scope="scope">
						{{scope.row.remark || '--'}}
					</template>
				</el-table-column>
				<!--        <el-table-column prop="stage" label="账户余额" align="center"></el-table-column> -->
			</el-table>
			<!-- 账户记录列表分页 -->
			<div class="block">
				<el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
					:current-page="currpage" :page-sizes="[10, 50, 100]" :page-size="pagesize" background
					layout="total,prev, pager, next,sizes,jumper" :total="CountNum" style="margin-top:20px">
				</el-pagination>
			</div>
		</div>
		<!-- 账户记录结束 -->
	</div>
</template>

<script>
	import {
		accountRecent,
		advList,
		advShowAmount
	} from '@/api/agentApi.js'
	export default {
		data() {
			return {
				tradeTypeOptions: [{
					id:1,
					label: '充值'
				}, {
					id: 2,
					label: '消费'
				}],
				tradeType: '',
				tradeDateRange: [],
				adverData: [], // 广告列表
				dateList: ['近7天', '近14天', '近30天'], // Table选项
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				currentIndex: 0, // 索引
				serchList: {}, //  查询条件
				stateList: [], //  计划名称
				loading: false, //表格加载框
				dcLoading: false, //折线图加载框
				searchKey: ''
			}
		},
		mounted() {
			this.adverList({}, 1, this.pagesize) // 获取广告列表
			this.advShowAmount(7) // 默认近7天数据
		},
		methods: {
			onSearch(key='') {
				this.tradeDateRange = this.tradeDateRange || []
				this.searchKey = key
				this.adverList({},1,this.pagesize)
			},
			drawChart(x, y) { //折线图展示
				var dom = document.getElementById('echar');
				var echar = this.$echarts.init(dom);
				var option = {
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['近期消费']
					},
					xAxis: {
						type: 'category',
						data: x,
						axisTick: {
							show: false
						},
					},
					yAxis: {
						type: 'value',
						axisPointer: {
							show: true,
							triggerTooltip: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#e8e8e8'
							}
						},
						// min: 1000,
						// max: 6000
					},
					series: [{
						name: '近期消费',
						data: y,
						type: 'line',
						itemStyle: {
							color: '#1890FF'
						}
					}]
				};
				echar.setOption(option);
				setTimeout(function() {
					window.onresize = function() {
						echar.resize();
					}
				}, 200)
			},
			advShowAmount(num) { // 折线图日期数据
				this.dcLoading = true
				const data = {
					lastDays: num // 数据类型 ( 天 )
				}
				advShowAmount(data).then(res => {
					var x = res.data.date // X轴数据(日期)
					var y = res.data.sum // Y轴数据（总消费）
					this.drawChart(x, y)
					this.dcLoading = false
				}).catch(err => {
					console.log(err)
				})
			},
			Atpitch(index) { // 折线图时间选择索引
				this.currentIndex = index
				switch (index) {
					case 0:
						this.advShowAmount(7)
						break
					case 1:
						this.advShowAmount(14)
						break
					case 2:
						this.advShowAmount(30)
						break
					default:
						this.advShowAmount(7)
				}
			},
			adverList(serchJson, currpage, pageSize) { // 广告列表
				this.loading = true
				const data = serchJson
				data["page"] = currpage //	当前页数
				data["limit"] = pageSize // 每页条数
				data["type"] = this.tradeType // 审核状态(1待审核 2已通过 3已拒绝 100全部)
				data['date'] = this.searchKey
				data['start_date'] = this.tradeDateRange.length>1 ? this.tradeDateRange[0] : ''
				data['end_date'] = this.tradeDateRange.length>1 ? this.tradeDateRange[1]: ''
				accountRecent(data).then(res => {
					this.adverData = res.data.list // 广告列表
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.currpage = currpage //分页显示当前页
					this.pagesize = pageSize
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(num) { // 分页
				this.adverList({}, num, this.pagesize)
			},
			handleSizeChange(num) { //分页设置显示条数
				this.adverList({}, 1, num)
			}
		}
	}
</script>

<style lang="scss" scoped>
	#body {
		background: #f0f2f5;
	}

	.active {
		//检测人滑动点击css
		color: #1890FF;
		font-size: 14px;
	}

	.topBox {
		width: 100%;
		background: #FFFFFF;
		margin: 0 auto;
		margin-bottom: 20px;

		.chartTitle {
			height: 51px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #E8E8E8;

			.facility {
				font-size: 18px;
				line-height: 48px;
				border-bottom: 2px solid #1890FF;
				color: #1890FF;
				margin-left: 32px;
				font-family: 萍方-简;
				font-weight: normal;
			}

			ul {
				padding: 0;
				margin: 0;
				font-size: 14px;
				margin-right: 25px;

				li {
					float: left;
					list-style: none;
					padding-left: 33px;
				}

				li:hover {
					cursor: pointer
				}
			}
		}
	}

	.bottomBox {
		width: 100%;
		display: flex;
		background: #FFFFFF;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center;

		.headerBox {
			display: flex;
			height: 52px;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #E8E8E8;

			p {
				line-height: 49px;
				font-size: 18px;
				font-family: 萍方-简;
				font-weight: normal;
				color: #1890FF;
				margin-left: 32px;
				border-bottom: 2px solid #1890FF;
			}
		}
	}
</style>